<template>
  <div class="packingWork">
    <div class="breadcrumb_box">
      <Breadcrumb>
        <BreadcrumbItem v-for="(item, index) in breadcrumbList" :key="index" :to="item.path ? item.path : ''">{{ item.name }}</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <!-- 当前拣货单 -->
    <div class="pickHeader clearfix">
      <div>
        <Row>
          <Col span="15">
            <div style="fontSize:16px;marginLeft:10px;height: 40px;">
              {{ $t('key1003964') }}<span style="font-weight: bold;">{{ pickingGoodsNo }} {{ packageGoodsTypeName }}</span>
            </div>
          </Col>
          <Col span="9">
            <div class="fr" style="display: inline-block;float: right;margin-right: 10px;">
              <div style="fontSize:16px;marginLeft:10px;font-weight: bold;color: #fff;height: 40px;display: inline-block">
                {{ $t('key1003965') }}{{ userName }}
              </div><!--
              <Button size="small" style="marginLeft:30px;" @click="quitWork">退出当前作业</Button>-->
              <Button size="small" style="marginLeft:30px;" @click="finishWork">{{ $t('key1003937') }}</Button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
    <!-- content -->
    <div class="">
      <Row>
        <Col style="width: 100%;">
          <!-- 输入sku开始分拣 -->
          <div class="skuInput">
            {{ $t('key1003882') }}
            <Input
              v-model.trim="skuInput"
              autofocus
              :element-id="'skuInputt'"
              ref="skuInput"
              style="width:300px;marginLeft:15px;"
              @on-enter="skuStart">
            </Input>
          </div>
        </Col>
      </Row>
      <Row>
        <Col class="packProcessCol">
          <div class="packProcess">
            <Steps :current="packageProcessStep">
              <Step :title="$t('key1003956')" icon="ios-person"></Step>
              <Step :title="$t('key1003959')" icon="ios-person"></Step>
              <Step :title="$t('key1003960')" icon="ios-person"></Step>
            </Steps>
          </div>
        </Col>
      </Row>
      <div class="scanPackageShow">
        <Row justify="center" align="middle" type="flex">
          <Col span="16">
            <Row class="bar">
              <Col>
                {{ $t('key1003969') }}
              </Col>
            </Row>
            <div class="orderTable">
              <Table
                border
                :loading="tableLoading"
                max-height="550"
                :columns="printedPackageColumn"
                :data="printedPackageData"></Table>
            </div>
          </Col>
          <!-- 货品信息 -->
          <Col span="8">
            <div v-show="showSku">
              <!-- 货品信息 -->
              <div class="productInfo">
                <Row justify="center" align="middle" type="flex">
                  <Col>
                    <Tooltip placement="left" theme="light" :transfer="true" transfer-class-name="set_bigimg_style">
                      <img class="img_styles" :src="setImgPath(productImg)" @error="setErrorImg($event)" alt=""/>
                      <template #content>
                        <img :ref="'thumbnailImg'" @load="handlerOnloadImg('thumbnailImg')"
                          :src="setImgPath(productImg)" @error="setErrorImg($event)" alt=""/>
                      </template>
                    </Tooltip>
                  </Col>
                </Row>
                <Row justify="center" align="middle" type="flex">
                  <div style="fontSize:22px;marginTop:20px;">
                    SKU：<span style="color:#008000;">{{ sku }}</span>
                  </div>
                </Row>
                <Row justify="center" align="middle" type="flex">
                  <div style="fontSize:22px;marginTop:20px;">
                    {{ $t('key1001838') }}<span style="color:#008000;">{{ title }}</span>
                  </div>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
    <!-- sku输入异常 -->
    <Modal v-if="warnModalStatus" v-model="warnStatus" :title="$t('key1003961')" width="500">
      <!--一个隐藏的input，用于回车时，关闭弹窗-->
      <Input :element-id="'skuAbnormal'" ref="skuAbnormal" @on-enter="cancel"
        style="opacity: 0;position: absolute;top: 50px;left: 0; z-index: -10;">
      </Input>
      <div style="padding:10px 20px;">
        <Row>
          <Col span="6">
            <div>
              <Icon type="md-information-circle" color="#2b85e4" style="font-size:50px"></Icon>
            </div>
          </Col>
          <Col
            span="16"
            v-if="findWarnStatus"
            style="font-size:16px">{{ $t('key1003962').replace('{warnSku}', warnSku) }}
          </Col>
          <Col span="16" v-if="warnPrintStatus" style="font-size:16px">{{ $t('key1003970') }}</Col>
          <Col span="16" v-if="warnMatchStatus" style="font-size:16px">
            <div>{{ $t('key1003971') }}</div>
            <div style="marginTop:3px;">
              {{ $t('key1003972') }}
            </div>
            <div style="marginBottom:15px;">
              {{ $t('key1003973') }}
            </div>
          </Col>
          <Col span="16" v-if="errorCode === 116104" style="font-size:16px">{{ $t('key1003974') }}</Col>
          <Col span="16" v-if="errorCode === 116101" style="font-size:16px">{{ $t('key1003975') }}</Col>
          <Col span="16" v-if="errorCode === 116102" style="font-size:16px">{{ $t('key1003976') }}</Col>
          <Col span="16" v-if="errorCode === 116114" style="font-size:16px">{{ $t('key1003977') }}</Col>
          <Col span="16" v-if="errorCode === 116113" style="font-size:16px">{{ $t('key1003978') }}</Col>
          <Col span="16" v-if="errorCode === 116118" style="font-size:16px">{{ $t('key1003979') }}</Col>
        </Row>
      </div>
      <template #footer>
        <Button style="marginRight:210px;" @click="cancel">{{ $t('key1000643') }}</Button>
      </template>
    </Modal>
    <!-- 结束作业 -->
    <endWarnModal
      ref="endWarnModal"
      :pageType="pageType"
      :userId="userName"
      :pickingGoodsNo="pickingGoodsNo"
      :pickingGoodsId="pickingGoodsId"
      :currentPackageData="printedPackageData"></endWarnModal>
    <Modal v-model="model1" :title="modalTitle" @on-ok="printMt">
      <div>{{ modalSubTitle }}：
        <InputNumber :max="maxNumber" :min="1" v-model="reviveNumber"></InputNumber>
      </div>
    </Modal>
  </div>
</template>

<script>
import { aliasb039bd6e4486415fba0a6b42f8cd95d1 } from '@/customFolder/customVueAlias.js';

import api from '@/api/';
import Mixin from '@/components/mixin/common_mixin';
import endWarnModal from './endWarnModal';
import {downloadControl, getUserInfoByUserIdsData, qs} from "@/utils/common";

export default {
  mixins: [Mixin],
  components: {
    endWarnModal
  },
  data() {
    var self = this;
    return {
      errorCode: 0,
      model1: false,
      reviveNumber: 1,
      maxNumber: 10,
      modalTitle: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006169'),
      modalSubTitle: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006251'),
      pageType: 'prepareGoods',
      scanSkuPackageId: '',
      pickingGoodsNo: '',
      packageGoodsTypeName: '',
      pickingGoodsId: '',
      userName: '',
      skuInput: null, // 输入的sku
      showSku: false,
      sku: '',
      title: '',
      printType: '',
      productImg: null, // 当前货品的图片
      packageProcessStep: 0,
      filenodeViewTargetUrl: localStorage.getItem('imgUrlPrefix'),
      printedPackageColumn: [
        {
          title: 'NO.',
          width: 80,
          align: 'center',
          key: 'index',
          fixed: 'left',
          type: 'index',
        },
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000966'),
          key: 'packageCodes',
          align: 'center',
          render(h, params) {
            return h('div', params.row.packageCodes.map(i => h('p', i)));
          }
        },
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000161'),
          key: 'imagePath',
          width: 120,
          render(h, params) {
            return self.tableImg(h, params, 'imagePath');
          }
        },
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003994'),
          key: 'merchantShippingMethodId',
          align: 'center',
          render(h, params) {
            let text = '';
            let packageGoodsType = params.row.packageGoodsType;
            if (packageGoodsType === 'MIX') {
              let skusList = params.row.skus;
              let list = skusList.filter((item) => {
                return item.sku === params.row.sku
              });
              let name = list[0].name || '';
              let variations = list[0].variations || '';
              text = name + ' / ' + variations;
            } else {
              text = params.row.merchantShippingMethodId;
            }
            return h('span', text);
          }
        },
        {
          title: 'SKU',
          key: 'sku',
          align: 'center'
        },
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003913'),
          key: 'quantity',
          align: 'center'
        },
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006252'),
          key: 'isPrinted',
          align: 'center',
          render: (h, params) => {
            return h('span', params.row.printNumber);
          }
        },
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000283'),
          key: 'operation',
          width: '200',
          align: 'center',
          render: (h, params) => {
            return h('optsButtonList', {
              props: {
                operationList: [
                  {
                    text: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006169'),
                    show: true,
                    clickFn: () => {
                      this.printStatus = true;
                      this.packageIds = [params.row.packageId];
                      this.printObj = params.row;
                      document.getElementById('skuInputt').focus();
                      this.printType = 'again';
                      this.modalTitle = aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006169');
                      this.modalSubTitle = aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006251');
                      this.maxNumber = Number(params.row.printNumber);
                      this.reviveNumber = 1;
                      this.model1 = true;
                    }
                  },
                  {
                    text: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002824'),
                    show: params.row.quantity > params.row.printNumber,
                    clickFn: () => {
                      this.printStatus = true;
                      this.packageIds = [params.row.packageId];
                      this.printObj = params.row;
                      document.getElementById('skuInputt').focus();
                      this.printType = 'print';
                      this.modalTitle = aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002824');
                      this.modalSubTitle = aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001768');
                      this.printSku = params.row.sku;
                      this.maxNumber = Number(params.row.quantity - params.row.printNumber);
                      this.reviveNumber = 1;
                      this.model1 = true;
                    }
                  }
                ]
              },
              on: {
                setWidth(value) {
                  if (value) {
                    self.optionBtnWidth.push(value);
                    params.column.width = Math.max(...self.optionBtnWidth);
                    self.$forceUpdate();
                  }
                }
              }
            });
          }
        }
      ],
      printSku: '',
      printedPackageData: [],
      currentPackageData: [],
      breadcrumbList: [
        {name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000018')},
        {name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003896')},
        {name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006170'), path: '/wms/packWorking'},
        {name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003876')}
      ],
      warnStatus: false,
      findWarnStatus: false, // 查询sku警告
      warnPrintStatus: false, // 控制是否显示待打印警告
      warnMatchStatus: false, // 控制是否显示匹配异常错误
      warnModalStatus: false, // 是否生成模态框
    };
  },
  created() {
    this.deletePackingCache();
    this.getDetail();
  },
  methods: {
    printMt() {
      let v = this;
      let falg = v.printObj.isPrinted === 1;
      v.printSku = v.printObj.sku;
      v.getLabelPath(v.printObj.sku)
        .then((labelPath) => {
          let printNumber = 0;

          function ePrint() {
            printNumber++;
            v.print(labelPath, v.printObj.packageCode, !falg, null, () => {
              if (printNumber < v.reviveNumber) {
                ePrint();
              }
            });
          }

          ePrint();
        });
    },
    setPackingCache(pickingGoodsNo, obj) {
      let arr = [];
      try {
        if (localStorage.getItem('picking_cache')) {
          arr = JSON.parse(localStorage.getItem('picking_cache'));
        } else {
          arr = [];
        }
        if (arr.some(i => i.pickingGoodsNo === pickingGoodsNo)) {
          arr.forEach(i => {
            if (i.pickingGoodsNo === pickingGoodsNo) {
              i.data = obj;
            }
          });
        } else {
          arr.push({
            time: Date.now(),
            pickingGoodsNo: pickingGoodsNo,
            data: obj
          });
        }
        localStorage.setItem('picking_cache', JSON.stringify(arr));
      } catch (e) {

      }
    },
    getPackingCacheByNo(pickingGoodsNo) {
      try {
        let arr = [];
        if (localStorage.getItem('picking_cache')) {
          arr = JSON.parse(localStorage.getItem('picking_cache'));
        }
        return arr.filter(i => i.pickingGoodsNo === pickingGoodsNo);
      } catch (e) {
        return [];
      }

    },
    /**
     * 删除过期缓存 有效期7天
     * @date: 2021/11/19 11:26
     */
    deletePackingCache() {
      try {
        let arr = [];
        if (localStorage.getItem('picking_cache')) {
          arr = JSON.parse(localStorage.getItem('picking_cache'));
        }
        if (this.typeOf(arr) !== 'array') {
          arr = [];
        }
        let newArr = arr.filter(i => i.time > Date.now() - 1000 * 3600 * 24 * 7);
        localStorage.setItem('picking_cache', JSON.stringify(newArr));
      } catch (e) {

      }

    },
    getDetail() {
      let v = this;
      v.axios.get(api.get_wmsPickingGoods_getScanningPickingGoods).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas;
          if (data) {
            let pos = [];
            if (data.packages !== null && data.packages.length > 0) {
              let hasSku = [];
              data.packages.forEach((n, i) => {
                n.skus = v.handleMultilinguaTableFields(n.skus, ['name']),
                  n.skus.forEach(l => {
                    if (hasSku.includes(l.sku)) {
                      pos.forEach(j => {
                        if (j.sku === l.sku) {
                          if (!j.packageCodes.includes(n.packageCode)) {
                            j.packageCodes.push(n.packageCode);
                          }
                          j.quantity += j.quantity;
                        }
                      });
                    } else {
                      hasSku.push(l.sku);
                      pos.push({
                        ...n,
                        ...l,
                        packageCodes: [n.packageCode],
                        printNumber: 0,
                        packageGoodsType: data.packageGoodsType
                      });
                    }
                  });
              });
            }
            let obj = this.getPackingCacheByNo(data.pickingGoodsNo);
            if (obj && obj.length) {
              obj[0].data.forEach((n, i) => {
                pos.forEach(j => {
                  if (n.sku === j.sku) {
                    j.printNumber = n.printNumber;
                  }
                });
              });
            }
            v.printedPackageData = pos;
            v.pickingGoodsNo = data.pickingGoodsNo;
            let pathlist = [];
            if (v.pickingGoodsNo) {
              pathlist.push({
                name: v.pickingGoodsNo
              });
              let arr = [...v.breadcrumbList, ...pathlist];
              v.breadcrumbList = v.uniqueFunc(arr, 'name');
            }
            v.pickingGoodsId = data.pickingGoodsId;
            const pickTypeObj = {
              SS: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000180'),
              SM: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000180'),
              MM: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000179')
            };
            v.packageGoodsTypeName = pickTypeObj[data.packageGoodsType];
            v.packageGoodsType = data.packageGoodsType;
            getUserInfoByUserIdsData([data.userId],v).then((userData)=> {
              if (userData) {
                v.userName = userData[data.userId].userName;
              }
            });
            v.getPickingList();
          } else {
            v.$Message.error(aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006171'));
            return false;
          }
        }
      });
    },
    getPickingList() {
      // 获取拣货单列表
      let v = this;
      let pos = [];
      if (v.pickingGoodsId) {
        v.axios.get(api.get_packageGoods_queryByPickingGoodsId + v.pickingGoodsId).then(response => {
          if (response.data.code === 0) {
            let data = response.data.datas;
            data.sortingPackageGoodsResult.forEach((n, i) => {
              pos.push({
                pictureUrl: n.pictureUrl,
                productGoodsId: n.productGoodsId,
                quantity: n.quantity,
                sku: n.sku,
                upc: n.upc,
                title: n.title,
                variations: n.variations,
                haveNum: 0 // 拣货单已分拣货品数量
              });
            });
            v.pickData = pos;
          }
        });
      }
    },
    cancel() {
      // 取消弹框
      let v = this;
      v.warnStatus = false;
      v.findWarnStatus = false;
      v.warnPrintStatus = false;
      v.warnMatchStatus = false;
      v.errorCode = 0;
      document.getElementById('skuInputt').focus();
    },
    skuStart() {
      let v = this;
      if (v.hasSku(v.skuInput)) {
        if (!v.currentSkuIsFull(v.skuInput)) {
          v.printSku = v.skuInput;
          v.setSkuBox(v.skuInput);
          v.printType = 'print';
          v.getLabelPath(v.skuInput)
            .then((labelPath) => {
              v.print(labelPath, v.printObj.packageCode, true); // => finishPrint
            });
        } else {
          v.$Message.info('sku：' + v.skuInput + aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006253'));
          v.skuInput = '';
        }
      } else {
        v.$Message.info('sku：' + v.skuInput + aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006254'));
        v.skuInput = '';
      }
    },
    finishPrint(callback) {
      this.packageProcessStep = 2;
      setTimeout(() => {
        this.packageProcessStep = 0;
      }, 300);
      callback && callback();
      if (this.printType === 'again') return;
      this.printedPackageData.forEach(i => {
        if (i.sku === this.printSku) {
          i.printNumber++;
          this.skuInput = '';
          this.setPackingCache(this.pickingGoodsNo, this.printedPackageData.map(k => {
            return {
              sku: k.sku,
              printNumber: k.printNumber
            };
          }));
        }
      });

    },
    setSkuBox(sku) {
      this.printedPackageData.forEach(i => {
        if (i.sku === sku) {
          this.sku = sku;
          this.title = i.name;
          this.productImg = i.productImg;
          this.showSku = true;
          this.printObj = {
            packageCode: i.packageId
          };
        }
      });
    },
    getLabelPath(sku) {
      return new Promise(resolve => {
        this.axios.post(api.post_packageInfo_printingSurfaceSingle + `?sku=${sku}`).then(response => {
          if (response.data.code === 0) {
            resolve(response.data.datas);
          }
        });
      });
    },
    currentSkuIsFull(sku) {
      return this.printedPackageData.filter(i => i.sku === sku).every(i => i.quantity <= i.printNumber);
    },
    hasSku(sku) {
      let skuArr = this.printedPackageData.map(i => i.sku);
      return skuArr.includes(sku);
    },
    print(path, postId, flag, orderBy, callback) {
      // JSONP /printResult flag是否调接口
      let v = this;
      let originPostId = postId;
      postId = postId + '' + parseInt(Math.random() * 100000000);
      v.packageProcessStep = 1;
      if (path) {
        v.printHtmlUrl(path, postId).then(res => {
          if (res.printType === 'cainiao') {
            // 菜鸟打印完成
            v.finishPrint(callback);
            return;
          }
          let jsonObj = res;
          if (jsonObj.content) {
            jsonObj.content = encodeURIComponent(jsonObj.content);
          }
          let instance = v.axios.create({
            timeout: 3000,
            headers: {
              'Content-Type': 'multipart/form-data; charset=UTF-8'
            }
          });
          v.tableLoading = true;
          delete v.axios.defaults.headers.post['X-Requested-With'];
          instance.post('http://localhost:10099/print', qs(jsonObj)).then(response => {
            v.tableLoading = false;
            if (response.status === 200) {
              v.startPrintMonitor(jsonObj, originPostId, flag, orderBy, callback);
            }
          }).catch(() => {
            let fileName = '/tool/TongtoolPrinter.exe';
            downloadControl(fileName).then(()=> {
              v.tableLoading = false;
            });
          });
        });
      } else {
        v.$Message.error(aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003323'));
        v.tableLoading = false;
        return false;
      }
    },
    startPrintMonitor(jsonObj, postId, flag, orderBy, callback) {
      let v = this;
      let obj = {
        packageIds: [postId]
      };
      if (orderBy) {
        obj.printOrderBy = orderBy;
      }
      // jsonObj.responseType = 1;
      delete jsonObj.content;
      delete v.axios.defaults.headers.post['X-Requested-With'];
      // instance.post('http://localhost:10099/printResult?' + Qs.stringify(jsonObj))
      v.axios.jsonp('http://localhost:10099/printResult?' + qs(jsonObj)).then(response => {
        console.log(typeof response.data);
        if (response.data === '0') {
          v.clearTime = setTimeout(function () {
            v.startPrintMonitor(jsonObj, postId, flag, orderBy, callback);
          }, 500);
        } else if (response.data === '2') {
          document.getElementById('skuInputt').focus();
          v.$Message.error(aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003910'));
          callback && callback();
        } else if (flag) {
          // 不是重复打印，需要调接口
          // let param = {};
          // param.pickingGoodsId = v.pickingGoodsId;
          // param.packageId = v.scanSkuParams.packageId;
          v.finishPrint(callback);
        }
      });
    },
    quitWork() {
      // 退出当前作业
      let v = this;
      v.axios.put(api.put_wmsPickingGoods_quitPacking + v.pickingGoodsId).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas;
          v.$refs.endWarnModal.isFinishStatus = true;
          if (data.isFinish) {
            // 是否可以走结束流程, true正常false异常
            v.$refs.endWarnModal.isFinish = true;
            v.$refs.endWarnModal.endStatus = true;
            v.$refs.endWarnModal.normalData();
          } else {
            if (data.isSingle) {
              // true一个人 false多人
              v.$refs.endWarnModal.isFinish = true;
              v.$refs.endWarnModal.endStatus = false;
              v.$refs.endWarnModal.abnormalData();
            } else {
              if (data.isQuit) {
                v.$router.push('/wms/packWorking');
              }
            }
          }
        }
      });
    },
    finishWork() {
      // 结束作业
      let v = this;
      v.$refs.endWarnModal.isFinishStatus = true;
      if (v.printedPackageData.some(i => i.quantity > i.printNumber)) {
        // 有异常
        v.$refs.endWarnModal.isFinish = true;
        v.$refs.endWarnModal.endStatus = false;
        v.$refs.endWarnModal.abnormalData();
      } else {
        // 无异常
        v.$refs.endWarnModal.isFinish = true;
        v.$refs.endWarnModal.endStatus = true;
        v.$refs.endWarnModal.normalData();
      }
    },
  }
};
</script>

<style lang="less" scoped>
.packingWork {
  .breadcrumb_box {
    padding: 12px 0 0 15px;
  }
}

.pickHeader {
  width: 99%;
  height: 40px;
  margin: 10px auto;
  line-height: 40px;
  background-color: rgba(247, 150, 70, 1);
}

.skuInput {
  margin-left: 10px;
  margin-top: 5px;
  margin-right: 12px;
  padding: 15px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  background-color: rgba(43, 120, 228, 1);
}

.skuBox {
  height: 457px;
  margin-top: 10px;
  padding-top: 20px;
  margin-left: 10px;
  margin-bottom: 10px;
  background-color: #fff;
  border: 1px solid rgb(164, 197, 235);
}

.pickingGoods {
  width: 99%;
  margin: 10px auto;
}

.pickBox {
  width: 100px;
  height: 50px;
  margin-top: 10px;
  border: 1px solid #000;
}

.productBox {
  width: 300px;
  height: 170px;
  border: 1px solid #000;
  font-size: 24px;
  text-align: center;
  line-height: 170px;
  font-weight: bold;
}

.productInfo {
  background-color: rgb(252, 251, 225);
  .img_styles {
    vertical-align: middle;
    width: auto;
    max-width: 277px;
    height: 171px;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 50px;
  }
}

.scanPackageShow {
  border: 1px solid rgb(215, 215, 215);
  margin-left: 10px;
  margin-right: 12px;
  background-color: rgb(252, 251, 225);
}

.warnBox {
  width: 80%;
  height: 400px;
  border: 1px solid #717274;
  margin-left: 5%;
  background-color: #fff;
}

.warnBtn {
  width: 200px;
  height: 25px;
  line-height: 25px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
  margin-left: 40%;
  background-color: #aaa;
}

.packProcess {
  width: 700px;
  height: 50px;
  margin: 10px auto;
}

.packProcessCol {
  width: 100%;
  border-style: solid;
  margin-left: 10px;
  margin-right: 12px;
  margin-top: 15px;
  border-width: 1px;
}

.bar {
  margin-left: 10px;
  margin-top: 15px;
  margin-right: 12px;
  padding: 15px;
  font-size: 13px;
  font-weight: bold;
  color: #fff;
  background-color: rgba(43, 120, 228, 1);
}

.cleanScanLink {
  padding-right: 12px;
  float: right;
}

.orderTable {
  padding: 0 12px;
}
</style>
<style>

.packProcess .ivu-steps .ivu-steps-title {
  display: block;
}

.packProcess .ivu-steps .ivu-steps-custom .ivu-steps-title {
  margin-left: -20px;
}

.packProcess .ivu-steps-item {
  overflow: initial
}
</style>
